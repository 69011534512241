@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  html,
  body,
  #__next {
    @apply h-full;
    @apply min-h-full;
    @apply bg-gray-100;
  }

  html,
  body {
    font-family: 'Poppins', sans-serif;
  }
}

.custom-slider .rangeslider .rangeslider__handle {
  z-index: 2;
  width: 15px !important;
  height: 15px !important;
  background-color: black;
}

.custom-labels .rangeslider .rangeslider__handle {
  width: auto !important;
  min-width: 46px !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
}

@import './component/ads-preview.scss';
@import './component/fonts';
@import './component/input';
@import './component/input-range';
@import './component/onboarding';
@import './component/phone-input';
@import './component/react-select';
