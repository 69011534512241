.react-select {
  &__control {
    min-height: 3rem !important;
    border-radius: 0.75rem !important;
    border-color: #181a27 !important;

    &--is-focused {
      border-radius: 0.75rem !important;
      border-color: #181a27 !important;
      box-shadow: none !important;
      width: 100% !important;
    }
  }

  &__value-container {
    padding-left: 0.875rem !important;
  }

  &__placeholder {
    font-size: 0.875rem !important;
    color: #9fa9b3 !important;
  }

  &__input {
    font-size: 0.875rem !important;
    color: #181a27 !important;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &__single-value {
    color: #181a27 !important;
    font-size: 0.875rem !important;
  }

  &__multi-value {
    background-color: #f0f2f3 !important;
    border-radius: 0.375rem !important;

    &__label {
      color: #181a27 !important;
      font-size: 0.875rem !important;
    }
  }
}

.error {
  .react-select {
    &__control {
      border-color: #ec4c4c !important;

      &--is-focused {
        border: none !important;
        border-color: none !important;
        box-shadow: 0 0 0 1px #3166f4 !important;
      }
    }
  }
}

.react-select__clear-indicator {
  display: none !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__dropdown-indicator {
  color: #181a27 !important;
  cursor: pointer;
}

.react-select__menu {
  width: 100% !important;
  z-index: 10 !important;
}
.css-b62m3t-container {
  width: 100% !important;
}
