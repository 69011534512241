.react-tel-input {
  --phone-input-border-color: #181a27;

  .form-control {
    width: 100% !important;
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
    height: unset !important;
    border-radius: 0.625rem !important;
    padding-left: 5rem !important;
    font-size: 0.875rem !important;
    line-height: 1.3125rem !important;
    color: #102740 !important;
    border-color: var(--phone-input-border-color);

    &:focus,
    &:focus + .flag-dropdown {
      --phone-input-border-color: var(--phone-input-border-color) !important;
    }
  }

  &--error {
    --phone-input-border-color: #ec4c4c;
  }

  .flag-dropdown {
    border-top-left-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
    width: 4.5rem !important;
    background-color: #ffffff;
    border-color: var(--phone-input-border-color);

    .selected-flag {
      width: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding: 0 !important;
      background: transparent !important;
      .flag {
        margin-top: -12px !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }

    .country-list {
      width: 25rem !important;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
      border-radius: 0.625rem;
    }
  }
}
// .react-tel-input .tr {
//   background-position: 0 -143px;
//   width: 22px !important;
//   height: 22px !important;
//   background-position: center !important;
//   background-size: cover !important;
//   background-image: url('/turkeyflag.svg') !important;
// }
