@font-face {
  font-family: 'Michelin';
  src: url('/fonts/michelin/Michelin-Regular.otf');
}
@font-face {
  font-family: 'Michelin';
  font-weight: 300;
  src: url('/fonts/michelin/Michelin-Light.otf');
}
@font-face {
  font-family: 'Michelin';
  font-weight: normal;
  src: url('/fonts/michelin/Michelin-Regular.otf');
}
@font-face {
  font-family: 'Michelin';
  font-weight: 600;
  src: url('/fonts/michelin/Michelin-Semibold.otf');
}
@font-face {
  font-family: 'Michelin';
  font-weight: 700;
  src: url('/fonts/michelin/Michelin-Bold.otf');
}
@font-face {
  font-family: 'Michelin';
  font-weight: 900;
  src: url('/fonts/michelin/Michelin-Black.otf');
}
