input[type='checkbox'] {
  color: black !important;
}

input[type='checkbox']:focus {
  --tw-ring-color: none !important;
}

[type='checkbox']:checked {
  background-image: url('/CheckIcon.svg') !important;
}

input[type='text'] {
  width: 100% !important;
}

input[type='text']:focus {
  --tw-ring-color: none !important;
}

.account-checkbox{
  input[type='checkbox'] {
    color: rgb(79, 174, 108) !important;
    border-radius: 100% !important;
  }

  [type='checkbox']:checked {
    background-image: url('/GreenCheckIcon.svg') !important;
  }
}

textarea::-webkit-scrollbar {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.scrollbar::-webkit-scrollbar {
  width: 5px !important;
}