.story-loading{
    animation: progress-bar-facebook 10s infinite;
}

@keyframes progress-bar-facebook {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
