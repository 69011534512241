.input-range {
  margin-top: 1rem;

  &__track {
    height: 0.35rem;
    background: #e5ebf0;

    &--active {
      background: #3166f4;
    }
  }

  &__slider {
    user-select: none;
    background: #3166f4;
    border: 1px solid #3166f4;
  }

  &__label {
    font-family: 'Poppins', sans-serif;
    color: #102740;
    font-size: 0.875rem;

    &--value {
      top: -2rem;
    }

    &--min,
    &--max {
      display: none;
    }
  }
}
.rangeslider {
  margin: 14px 0 !important;
  background: #e6e6e6 !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 46px !important;
  height: 46px !important;
  border-radius: 10px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: none !important;
  position: absolute;
  width: 0px !important;
  height: 0px !important;
  top: 6px;
  left: 6px;
  border-radius: 0 !important;
  background-color: #dadada;
  box-shadow: 0 !important;
}

.rangeslider__handle-label {
  display: flex !important;
  justify-content: center !important;
  font-weight: 700 !important;
  user-select: none;
}

.rangeslider__handle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #3067f4 !important;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal {
  height: 5px !important;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none !important;
}

.rangeslider .rangeslider__handle {
  border: none !important;
  box-shadow: 0px -1px 8px 0px rgba(214, 214, 214, 0.75) !important;
}
